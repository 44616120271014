const form = document.querySelector('.lookup form')
const wrap = document.querySelector('.lookup .wrap')
const recaptchaKey = '6LeRz2QqAAAAAP_7xtyjwAXabho2JmMgHOb21QPC';

// const submitEndpoint = 'http://localhost:5001/dpi-fringe-candidates/us-central1/lookup'
const submitEndpoint = 'https://us-central1-dpi-candidate-search24.cloudfunctions.net/lookup'

form.addEventListener('submit', async e => {
    e.preventDefault()
    wrap.setAttribute('aria-busy', 'true')
    form.querySelector('button').setAttribute('disabled', 'disabled')
    wrap.style.minHeight = `${wrap.offsetHeight}px`

    const checkURL = new URL('check.svg', import.meta.url)
    const warnURL = new URL('warning.svg', import.meta.url)

    await grecaptcha.ready(async () => {
        const token = await grecaptcha.execute(recaptchaKey, { action: 'submit' })
        const data = new FormData(form)
        data.set('_token', token)

        const resp = await fetch(submitEndpoint, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: new URLSearchParams(data).toString()
        })
        const body = await resp.json()

        if (!body || !body.candidates || body.candidates.length === 0) {
            wrap.innerHTML = `
                <p class="no-results">For more information about how to vote this cycle, please visit <a href="https://iwillvote.com/il">IWillVote.com</a></p>`
        } else {
            wrap.innerHTML = ''

            if (body.partial) {
                wrap.innerHTML = '<p class="message">We couldn\'t find an exact match for your address, but these are some of the districts near you.</p>'
            }

            const legend = document.createElement('ul')
            legend.classList.add('candidates')
            const legendSupport = document.createElement('li')
            legend.appendChild(legendSupport)
            legendSupport.classList.add('support')
            legendSupport.innerHTML = `<img src="${checkURL.toString()}" alt="Check mark" title="These Democrats are counting on your vote!" /><p>These Democrats are counting on your vote!</p>`

            // const legendOppose = document.createElement('li')
            // legend.appendChild(legendOppose)
            // legendOppose.classList.add('oppose')

            // legendOppose.innerHTML = `<img src="${warnURL.toString()}" alt="Warning" title="This candidate is pursuing an extremist political agenda." /><p>This candidate is pursuing an extremist political agenda.</p>`

            wrap.appendChild(legend)

            for (const race of body.candidates) {
                const h3 = document.createElement('h3')
                h3.innerText = race.race
                const ul = document.createElement('ul')
                ul.classList.add('candidates')

                for (const candidate of race.candidates) {
                    const li = document.createElement('li')
                    ul.appendChild(li)
                    li.classList.add(candidate.position)
                    li.innerHTML = `
                        ${candidate.position === 'support'
                            ? `<img src="${checkURL.toString()}" alt="Check mark" title="This Democrat is counting on your vote." />`
                            : candidate.position === 'oppose'
                                ? `<img src="${warnURL.toString()}" alt="Warning" title="This candidate is pursuing an extremist political agenda." />`: '<span class="spacer" aria-hidden="true"></span>'}
                        <p>${candidate.name}</p>`
                }

                wrap.appendChild(h3)
                wrap.appendChild(ul)
            }
        }

        wrap.setAttribute('aria-busy', 'true')
    })
    return false
})
